import PropTypes from "prop-types";
import React from "react";
import { useForm } from "react-hook-form";
import Button from "./Button";

export default function Orcamento({ className, style }) {
  const { register, handleSubmit } = useForm();

  const onSubmit = (data, e) => {
    console.log(data, e);
  };
  return (
    <div className={`container-body my-auto ${className}`} style={style} >

        <h3 className="title-bar text-center uppercase">
          Peça um orçamento
        </h3>

      <div className="form mx-5">
        <form onSubmit={handleSubmit(onSubmit)} >
          <input
            name="Nome"
            ref={register}
            placeholder="Nome"
            className="my-5"
          />
          <input
            name="Email"
            ref={register}
            placeholder="E-mail"
            type="email"
            className="my-5"
          />
          <input
            name="Telefone"
            ref={register}
            placeholder="Telefone"
            className="my-5"
          />
          <textarea
            type="textarea"
            name="Mensagem"
            ref={register}
            rows={5}
            placeholder="Mensagem"
          />
          <Button className="w-full my-5" type="submit">
            Solicitar
          </Button>
        </form>
      </div>
    </div>
  );
}

Orcamento.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};
