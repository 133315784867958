import React from "react";
import Layout from "../components/layout";
import Orcamento from "../components/Orcamento";
import Estaca from "../images/Estaca.jpg";
import Lajes from "../images/LajesMacicas.jpg";
import Pilares from "../images/Pilares.jpg";
import PreMoldadosBanner from "../images/PreMoldadosBanner.png";
import Vigas from "../images/Vigas.jpg";
import SEO from "../components/seo";

function PreMoldados() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Pré-Moldados"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${PreMoldadosBanner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white ml-8 mt-24 uppercase base-title-golden">
          Pré-moldados
        </h1>
      </section>

      <div className="m-4 lg:flex">
        <div className="text-justify m-5 md:m-20 lg:w-11/12 xl:mx-48">
          <h3>
            Contamos com local apropriado para fabricação das peças pré-moldadas
            as quais atendem o padrão de qualidade e as solicitações estruturais
            que serão submetidas. Contamos com uma equipe capacitada desde a
            etapa de planejamento até a entrega das peças.
          </h3>
          <h3 className="my-12">
            Os elementos pré moldados contam com diversos pontos positivos em
            relação ao método convencional de construção, é uma indústria que
            opera de forma racionalizada com produção em série, proporcionando
            maior qualidade e velocidade, assim reduzindo o cronograma de
            entrega dos empreendimentos.
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 my-12">
            <div>
              <img
                src={Estaca}
                className="img-zoom mb-2"
                style={{
                  height: "300px",
                  width: "100%",
                }}
              />
              <h2>ESTACAS</h2>
            </div>
            <div>
              <img
                src={Lajes}
                className="img-zoom mb-2"
                style={{
                  height: "300px",
                }}
              />
              <h2>LAJES</h2>
            </div>
            <div>
              <img
                src={Pilares}
                className="img-zoom mb-2"
                style={{
                  height: "300px",
                }}
              />
              <h2>PILARES</h2>
            </div>
            <div>
              <img
                src={Vigas}
                className="img-zoom mb-2"
                style={{
                  height: "300px",
                }}
              />
              <h2>VIGAS</h2>
            </div>
          </div>
        </div>
        <Orcamento className="lg:w-1/2" />
      </div>
    </Layout>
  );
}

export default PreMoldados;
